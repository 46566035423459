<template>
    <a-modal :width="550" :maskClosable="false" :title="formData.id ? '编辑' : '新增'" :visible="visible"
        @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading">
        <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item ref="name" label="用户名" prop="name">
                <a-input v-model="formData.name" placeholder="请输入用户名" />
            </a-form-model-item>
            <a-form-model-item ref="mobile" label="手机号码" prop="mobile">
                <a-input v-model="formData.mobile" placeholder="请输入手机号码" />
            </a-form-model-item>
            <a-form-model-item ref="status" label="状态" prop="status">
                <a-select v-model="formData.status" placeholder="请选择状态" option-label-prop="label" >
                    <a-select-option v-for="item in [{ name: '正常', id: 1 }, { name: '停用', id: 2 }]" :key="item.id"
                        :value="item.id" :label="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item ref="roleList" label="角色" prop="roleList">
                <a-select v-model="formData.roleList" placeholder="请选择角色" option-label-prop="label" mode="multiple">
                    <a-select-option v-for="item in rolesList" :key="item.id"
                        :value="item.id" :label="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { request } from '@/utils/request'
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        confirmLoading: {
            type: Boolean,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                roleList: [
                    { required: true, message: '请选择角色', trigger: 'change' }
                ],
                status: [
                    { required: true, message: '请选择状态', trigger: 'change' }
                ],
            },
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            rolesList:[]
        }
    },
    mounted() {
        request(process.env.VUE_APP_API_BASE_URL + 'srm/role/list', 'post', {page:0,size:1000,status:1}).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.rolesList = data.data; 
                    
                } else {
                    this.$message.error(res.data.message)
                }
            })
    },
    methods: {
        handleCancel() {
            // 处理取消操作
            this.$emit('cancel');
        },
        handleSubmit() {
            // 处理表单提交
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.formData.menuList = this.menuList;
                    this.$emit('submit', this.formData)
                } 
            });
        },
    }
};
</script>
<style>
.title-text {
    font-weight: 700;
    font-size: 16px;
    color: #333;
}
</style>